import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Page, Metadata } from '~/components/Layout'
import PageSection from '~/sections/PageSection'
import Widget from '~/components/Reviews/Widget'

const ReviewsPage = ({ data: { page } }) => {
  const { metadata } = page
  const metaTitle = metadata?.title
  const metaDescription = metadata?.description?.description
  const metaImage = metadata?.image?.file?.url

  return (
    <Page>
      <Metadata
        title={metaTitle || page.name}
        description={metaDescription}
        image={metaImage}
      />
      {page.sections.map(section => (
        <PageSection key={section.id} section={section} />
      ))}
      <Widget />
    </Page>
  )
}

export default ReviewsPage

export const query = graphql`
  query ReviewsPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "reviews" }
      node_locale: { eq: $locale }
    ) {
      ...PageFragment
    }
  }
`

ReviewsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
}
