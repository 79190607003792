import styled from '@emotion/styled'

export const Wrapper = styled.section`
  padding: 48px 0;

  .footer-actions,
  .yotpo-helpful {
    display: none !important;
  }
`

export const Inner = styled.div`
  h2 {
    text-align: center;
  }

  .yotpo-label {
    display: none;
  }

  .yotpo-modal-bottom-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: none !important;
    padding-top: 0 !important;
  }

  .yotpo-bottomline {
    float: none !important;
    margin: 0 auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div#yotpo-testimonials-site-bottomline,
  #yotpo-site-reviews-tab,
  .yotpo .yotpo-modal-content input.primary-color-btn.write-review-button {
    opacity: 1 !important;
  }

  .yotpo-display-wrapper .text-m {
    font-size: 18px;
    font-weight: bold;
    margin: 16px 0;
    display: block;

    @media screen and (min-width: 992px) {
      font-size: 22px;
    }
  }
`
