import React from 'react'
import { Container, Heading } from 'theme-ui'
import { Inner, Wrapper } from './styles.js'
import {
  useReviews,
  useUpdateReviewPageWidget
} from '~/hooks/components/use-reviews'

const Widget = () => {
  useReviews()
  const [ref] = useUpdateReviewPageWidget()

  return (
    <Wrapper>
      <Container>
        <Inner>
          <Heading as="h2" variant="secondaryMedium">
            Customer Reviews
          </Heading>
          <div id="yotpo-testimonials-custom-tab" ref={ref} />
        </Inner>
      </Container>
    </Wrapper>
  )
}

export default Widget
